import { Locale } from '@core/Entities/Locale/Locale.entity';
import { SortTypes } from '@core/Entities/Search/Sort.entity';

const SORT_OPTIONS_UK = [
  'relevance-desc',
  'distance-asc',
  'year-asc',
  'year-desc',
  'price-asc',
  'price-desc',
  'mileage-asc',
  'mileage-desc',
  'date-active-desc',
];

const SORT_OPTIONS_FR = [
  'distance-asc',
  'price-asc',
  'price-desc',
  'discount-desc',
  'mileage-asc',
  'mileage-desc',
  'date-created-desc',
];

const DEFAULT_SORTING: { [key in Locale]?: SortTypes } = {
  uk: 'relevance-desc',
};

const SORT_KEY = 'sort';

export { DEFAULT_SORTING, SORT_KEY, SORT_OPTIONS_FR, SORT_OPTIONS_UK };
