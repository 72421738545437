import {
  MakeAggregations,
  MakeModelAtom,
  ModelAggregations,
  VariantAggregations,
} from '@core/Entities/Search/Aggregations/Aggregations.entity';
import { VariantAtom } from '@core/Entities/Search/Atoms/AvailableVariantsAtom.entity';

export const aggregationsToMakesModels = (
  makesAggs: MakeAggregations,
  modelsAggs: ModelAggregations,
  variantAggs?: VariantAggregations,
) => {
  const makes: MakeModelAtom[] = [];
  const models: MakeModelAtom[] = [];
  const variants: VariantAtom[] = [];

  Object.keys(makesAggs).forEach(makeKey => {
    const makeObj = makesAggs[makeKey];
    makes.push({
      key: makeKey,
      displayName: makeObj.label,
      count: makeObj.count,
      make: makeKey,
    });
  });

  Object.keys(modelsAggs).forEach(makeKey => {
    const modelsObj = modelsAggs[makeKey].models;

    const modelsFromMake = Object.keys(modelsObj).map(modelKey => {
      const modelObj = modelsObj[modelKey];
      return {
        key: modelKey,
        displayName: modelObj.label,
        count: modelObj.count,
        make: makeKey,
      } as MakeModelAtom;
    });

    models.push(...modelsFromMake);
  });

  if (variantAggs) {
    Object.keys(variantAggs).forEach(makeKey => {
      const modelsObj = variantAggs[makeKey].models;

      Object.keys(modelsObj).map(modelKey => {
        const modelObj = modelsObj[modelKey];
        const variantsObj = modelsObj[modelKey].variants;
        const trimsPerModel = Object.keys(variantsObj).map(variantKey => {
          const variantObj = variantsObj[variantKey];
          return {
            key: variantKey,
            displayName: variantObj.label,
            count: variantObj.count,
            make: makeKey,
            model: modelKey,
            modelDisplayName: modelObj.label,
          } as VariantAtom;
        });

        variants.push(...trimsPerModel);
      });
    });
  }

  return { makes, models, variants };
};
