import { FiltersProps } from './LocationFilter.entity';

const MAX_DISTANCE_KEY = 'max-distance';
const POSTCODE_KEY = 'postcode';
const REGION_KEY = 'region';
const LOCATION_FILTER_KEYS = ['postcode', 'lat', 'lon'];
const REGION_FILTER_KEYS = ['nuts-label', 'nuts1-code'];

const OPTIONS = [
  {
    id: POSTCODE_KEY,
    label: 'Code postal',
    value: POSTCODE_KEY,
  },
  {
    id: REGION_KEY,
    label: 'Région',
    value: REGION_KEY,
  },
];

const FILTERS_PROPS: FiltersProps = [
  {
    key: 'nuts-label',
    isMainFilterKey: true,
    filterType: REGION_KEY,
    restrictLocales: ['fr'],
  },
  {
    key: 'nuts1-code',
    filterType: REGION_KEY,
    isMainFilterKey: false,
    restrictLocales: ['fr'],
  },
  {
    key: 'lat',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
  {
    key: 'lon',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
  {
    key: POSTCODE_KEY,
    isMainFilterKey: true,
    filterType: POSTCODE_KEY,
  },
  {
    key: 'max-distance',
    isMainFilterKey: false,
    filterType: POSTCODE_KEY,
  },
];

const DISTANCE_STEP = 5;

const MAX_DISTANCE = {
  uk: 100,
  fr: 500,
};

const DEFAULT_DISTANCE = {
  uk: 60,
  fr: 60,
};

export {
  OPTIONS,
  MAX_DISTANCE,
  DEFAULT_DISTANCE,
  DISTANCE_STEP,
  FILTERS_PROPS,
  MAX_DISTANCE_KEY,
  REGION_KEY,
  POSTCODE_KEY,
  LOCATION_FILTER_KEYS,
  REGION_FILTER_KEYS,
};
