import { Locale } from '@core/Entities/Locale/Locale.entity';
import { LocationState } from '@core/Entities/Search/LocationState.entity';
import { commonApiURL } from '@core/Environment/Variables';
import { fetchJson } from '@core/Utils/Fetch/FetchJson';
import { formatUkPostcode } from '@core/Utils/Location/FormatUkPostcode';

const BASE_REGEX = /^[0-9]{5}$/;

const regexMap: Record<Locale, RegExp> = {
  fr: BASE_REGEX,
  uk: /^[a-z]{1,2}\d[a-z\d]?\s?\d[a-z]{2}$/i,
};

const validatePostcode = async (value: string, locale: Locale) => {
  const regex = regexMap[locale];

  const postcode = locale === 'uk' ? formatUkPostcode(value) : value;
  if (!regex?.test(postcode))
    return { isValid: false, error: true, location: { lat: 0, lon: 0 }, placeName: '' };

  try {
    const data = (await fetchJson(`${commonApiURL}/location/lookup/postcode/${postcode}`, {
      headers: {
        'x-heycar-tenant': locale,
      },
    })) as LocationState[];
    // placeName is only required for es
    if (data)
      return {
        location: data[0].location,
        isValid: true,
        error: false,
        placeName: data[0].placeName,
      } as LocationState;
  } catch (error) {
    return { isValid: false, error: true, location: { lat: 0, lon: 0 }, placeName: '' };
  }
};

export default validatePostcode;
