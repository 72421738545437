import { FR_LOCALE } from '@core/Constants/Locale.constants';
import { Locale } from '@core/Entities/Locale/Locale.entity';
import { frI15SearchApiURL, ukI15SearchApiURL } from '@core/Environment/Variables';

export const getMarketSpecificUrl = (locale?: Locale) => {
  switch (locale) {
    case FR_LOCALE:
      return frI15SearchApiURL || 'https://api.fr.staging.group-mobility-trader.de';
    default:
      return ukI15SearchApiURL || 'https://api.uk.prod.group-mobility-trader.com';
  }
};
