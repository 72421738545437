import { useEffect, useRef } from 'react';

import { Locale } from '@core/Entities/Locale/Locale.entity';
import { useAtom, useAtomValue } from 'jotai';

import { filtersListAtom } from 'Atoms/App/Plp/FiltersListAtom';
import {
  aggregationsAtom,
  initialAggregationsState,
} from 'Atoms/Search/AggregationsAtom/AggregationsAtom';
import { fetchAggregationsWithActiveFilters } from 'Utils/SearchFilters/FetchAggregations';

const useInitAggregations = (locale: Locale) => {
  const hasBeenInitialized = useRef(false);
  const [state, setMakesModels] = useAtom(aggregationsAtom);
  const activeFilters = useAtomValue(filtersListAtom);
  const hasInitialState = state.makes?.length > 0;

  const setError = () =>
    setMakesModels({
      ...state,
      ...initialAggregationsState,
      error: true,
    });

  const fetchMakesModels = async () => {
    setMakesModels({
      ...state,
      isLoading: true,
    });

    try {
      const aggregations = await fetchAggregationsWithActiveFilters(locale, activeFilters);

      setMakesModels({
        ...state,
        error: false,
        isLoading: false,
        ...aggregations,
      });
    } catch (error) {
      setError();
    }
  };

  /**
   * This hook and the use of a ref is to have different logic for when component is first mounted
   * and when it's re-rendered with new filters.
   * On initial mount - we should bypass the fetch call if we have initial state,
   * however for subsequent changes to the dependencies - that not longer matters, and we should
   * fetch the data.
   *
   * Note: because effects run twice in development - this will still result in an extra fetch
   * during development, but shouldn't be the case in production.
   */
  useEffect(() => {
    if (hasBeenInitialized.current) {
      fetchMakesModels();
    } else {
      hasBeenInitialized.current = true;
      if (hasInitialState) return;
      fetchMakesModels();
    }
  }, [hasInitialState, JSON.stringify(activeFilters)]);
};

export { useInitAggregations };
